<template>
  <CardToolbox>
    <SearchHeaderStyle>
      <sdPageHeader title="Expense Type">
        <template #subTitle>
          <a-input v-model:value="searchValue" @change="onHandleSearch" placeholder="Search by Name">
            <template #suffix>
              <sdFeatherIcons type="search" size="16" />
            </template>
          </a-input>
        </template>
      </sdPageHeader>
    </SearchHeaderStyle>
  </CardToolbox>
  <Main>
    <a-row :gutter="15">
      <a-col class="w-100" :md="14">
        <sdCards title="List">
          <div v-if="isLoading" class="spin">
            <a-spin />
          </div>

          <div v-else>
            <TableWrapper class="table-data-view table-responsive">
              <a-table @change="onHandleTableChange" :pagination="pagination" :dataSource="dataSource"
                :columns="columns" />
            </TableWrapper>
          </div>
        </sdCards>
      </a-col>
      <a-col class="w-100" :md="10">
        <sdCards :title="HeaderInfo">
          <FormValidationWrap>
            <VerticalFormStyleWrap>
              <a-form name="sDash_validation-form" ref="formRef" @finish="onSubmit" :model="formState" :rules="rules"
                :layout="formState.layout">
                <a-row :gutter="30">
                  <a-col :md="24" :xs="24">
                    <a-form-item ref="name" name="name" label="Name">
                      <a-input v-model:value="formState.name" placeholder="Name" />
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row :gutter="30">
                  <a-col :md="24" :xs="24">
                    <a-form-item label="Class">
                      <a-checkbox v-model:checked="formState.isOperation" name="operations">Operations</a-checkbox>
                      <a-checkbox v-model:checked="formState.isSupport" name="support">Support</a-checkbox>
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row :gutter="30">
                  <a-col :md="24" :xs="24">
                    <a-form-item ref="incomeOrOutcome" label="Income or outcome" name="incomeOrOutcome">
                      <a-select v-model:value="formState.incomeOrOutcome">
                        <a-select-option :value="1"> Income</a-select-option>
                        <a-select-option :value="2"> Outcome</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row :gutter="30">
                  <a-col :md="24" :xs="24">
                    <a-form-item label="Note">
                      <a-textarea v-model:value="formState.note" :style="{ width: '100%' }" placeholder="Note ..."
                        name="address" class="sDash_unresizable" />
                    </a-form-item>
                  </a-col>
                </a-row>
                <div class="sDash_form-action">
                  <sdButton v-if="localState.editionMode" v-on:click="switchUpdateOff()" class="sDash_form-action__btn"
                    size="large" type="light">
                    Cancel
                  </sdButton>
                  <sdButton v-if="localState.editionMode" type="primary" html-type="submit" class="sDash_form-action__btn"
                    size="large">
                    Edit
                  </sdButton>
                  <sdButton v-else type="primary" html-type="submit" class="sDash_form-action__btn" expenseType="large">
                    Add
                  </sdButton>
                </div>
              </a-form>
            </VerticalFormStyleWrap>
          </FormValidationWrap>
        </sdCards>
      </a-col>
    </a-row>
  </Main>
</template>
<script>
import {
  FormValidationWrap,
  VerticalFormStyleWrap,
} from "../forms/overview/Style";
import { Main, TableWrapper, CardToolbox, SearchHeaderStyle } from "../styled";
import { useStore } from "vuex";
import { computed, onMounted, reactive, ref, nextTick } from "vue";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Income Or Outcome",
    dataIndex: "incomeOrOutcome",
    key: "incomeOrOutcome",
  },
  {
    title: "Note",
    dataIndex: "note",
    key: "note",
  },
  {
    title: "Support Or Operation",
    dataIndex: "isSupportOrOperation",
    key: "isSupportOrOperation",
  },
  {
    title: "Actions",
    dataIndex: "action",
    key: "action",
    width: "90px",
  },
];

const ExpenseType = {
  name: "ExpenseType",
  components: {
    Main,
    TableWrapper,
    FormValidationWrap,
    VerticalFormStyleWrap,
    CardToolbox,
    SearchHeaderStyle,
  },
  setup() {
    const { state, dispatch } = useStore();
    const ExpenseTypeApi = computed(
      () => state.ExpenseTypeApi.data.data
    );
    const isLoading = computed(() => state.ExpenseTypeApi.loading);
    const searchValue = ref("");
    var loading = false;
    var expenseTypePagination = computed(
      () => state.ExpenseTypeApi.data
    );
    var sortDirections = "ascend";
    const HeaderInfo = computed(() => {
      if (localState.editionMode) {
        return "Edit bank operation type";
      }
      return "Add new bank operation type";
    });

    const localState = reactive({
      editionMode: false,
    });
    const formRef = ref();
    const formState = reactive({
      layout: "vertical",
      name: "",
      incomeOrOutcome: null,
      isOperation: false,
      isSupport: false,
      note: "",
    });

    const rules = {
      name: [
        {
          required: true,
          message: "This field is required",
          trigger: "change",
        },
        {
          min: 2,
          max: 100,
          message: "Field need to be longer then two and shorter then 100",
          trigger: "change",
        },
      ],
      incomeOrOutcome: [
        {
          type: "number",
          required: true,
          message: "This field is required",
          trigger: "change",
        },
      ],
    };

    onMounted(() => {
      dispatch("expenseTypeGetPagedData", pagination.value);
      localState.editionMode = false;
    });

    var pagination = computed(() => {
      return expenseTypePagination.value &&
        expenseTypePagination.value.succeeded == true
        ? {
          current: expenseTypePagination.value.pageNumber,
          total: searchValue.value
            ? expenseTypePagination.value.recordsFiltered
            : expenseTypePagination.value.recordsTotal,
          pageSize: expenseTypePagination.value.pageSize,
          OrderBy: sortDirections,
          searchValue: "",
          showExpenseTypeChanger: true,
          sorter: {
            order: sortDirections,
          },
        }
        : {
          current: 1,
          pageSize: 10,
          OrderBy: sortDirections,
          searchValue: "",
          sorter: {
            order: sortDirections,
          },
        };
    });
    const onHandleTableChange = (pagination) => {
      pagination = {
        ...pagination,
        sorter: {
          order: sortDirections,
        },
        current: pagination.current,
        OrderBy: sortDirections,
        searchValue: searchValue.value,
      };
      dispatch("expenseTypeGetPagedData", pagination);
    };
    const handleDelete = (id) => {
      const confirm = window.confirm("Are you sure delete this?");
      if (confirm) {
        dispatch("expenseTypeApiDataDelete", id);
      }
      return false;
    };

    const onHandleSearch = () => {
      sortDirections = "ascend";
      pagination = {
        ...pagination,
        current: 1,
        OrderBy: sortDirections,
        pageSize: 10,
        searchValue: searchValue.value,
      };

      dispatch("expenseTypeGetPagedData", pagination);
    };
    const switchUpdateOn = (expenseType) => {
      localState.editionMode = true;
      formState.name = expenseType.name;
      formState.id = expenseType.id;
      formState.incomeOrOutcome = expenseType.incomeOrOutcome;
      formState.isOperation = expenseType.isOperation;
      formState.isSupport = expenseType.isSupport;
      formState.note = expenseType.note;
    };
    const switchUpdateOff = () => {
      localState.editionMode = false;
      formState.note = "";
      formState.name = "";
      formState.isOperation = false;
      formState.isSupport = false;
      formState.id = 0;
      formState.incomeOrOutcome = null;
    };

    const dataSource = computed(() =>
      ExpenseTypeApi.value && ExpenseTypeApi.value.length
        ? ExpenseTypeApi.value.map((expenseType, key) => {
          const { id, name, incomeOrOutcome, note, isOperation , isSupport } = expenseType;

          return {
            key: key + 1,
            name,
            isSupportOrOperation: isSupport == true ? "Support" : isOperation == true ? "Operation":"",
            incomeOrOutcome: incomeOrOutcome == 1 ? "Income" : "Outcome",
            note,
            action: (
              <div class="table-actions">
                <a-button
                  class="edit"
                  onClick={() => switchUpdateOn(expenseType)}
                >
                  <sdFeatherIcons type="edit" expenseType={14} />
                </a-button>
                &nbsp;&nbsp;&nbsp;
                <a-button class="delete" onClick={() => handleDelete(id)}>
                  <sdFeatherIcons type="trash-2" expenseType={14} />
                </a-button>
              </div>
            ),
          };
        })
        : []
    );

    const onSubmit = () => {
      formRef.value
        .validate()
        .then(async () => {
          if (!localState.editionMode) {
            dispatch("expenseTypeSubmitData", {
              formState,
            });
          } else {
            dispatch("expenseTypeUpdateData", {
              formState,
            });
          }
          await nextTick();
          switchUpdateOff();
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    return {
      ExpenseTypeApi,
      formRef,
      rules,
      onSubmit,
      isLoading,
      localState,
      handleDelete,
      switchUpdateOn,
      switchUpdateOff,
      dataSource,
      HeaderInfo,
      columns,
      onHandleSearch,
      loading,
      sortDirections,
      formState,
      searchValue,
      expenseTypePagination,
      onHandleTableChange,
      pagination,
    };
  },
};

export default ExpenseType;
</script>
<style lang="scss">
.sDash_form-action {
  display: flex;
  justify-content: space-between;
}
</style>
